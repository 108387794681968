const iconLinks = [
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '57x57',
    href: 'apple-touch-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '114x114',
    href: 'apple-touch-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '72x72',
    href: 'apple-touch-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '144x144',
    href: 'apple-touch-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '60x60',
    href: 'apple-touch-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '120x120',
    href: 'apple-touch-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '76x76',
    href: 'apple-touch-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon-precomposed',
    sizes: '152x152',
    href: 'apple-touch-icon-152x152.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: 'favicon-196x196.png',
    sizes: '196x196',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: 'favicon-96x96.png',
    sizes: '96x96',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: 'favicon-32x32.png',
    sizes: '32x32',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: 'favicon-16x16.png',
    sizes: '16x16',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: 'favicon-128.png',
    sizes: '128x128',
  },
];
const iconMeta = [
  {
    name: 'msapplication-TileImage',
    content: 'mstile-144x144.png',
  },
  {
    name: 'msapplication-square70x70logo',
    content: 'mstile-70x70.png',
  },
  {
    name: 'msapplication-square150x150logo',
    content: 'mstile-150x150.png',
  },
  {
    name: 'msapplication-wide310x150logo',
    content: 'mstile-310x150.png',
  },
  {
    name: 'msapplication-square310x310logo',
    content: 'mstile-310x310.png',
  },
];
const meta = [
  {
    name: 'msapplication-TileColor',
    content: getProduct() === 'ns' ? '#fea839' : '#12529b',
  },
  {
    name: 'theme-color',
    content: getProduct() === 'ns' ? '#fea839' : '#12529b',
  },
];

iconLinks.forEach((link) => {
  const linkEl = document.createElement('link');
  Object.keys(link).forEach((key) => {
    if (key === 'href') {
      link[key] = `assets/fav/${getProduct()}/${link[key]}`;
    }
    linkEl.setAttribute(key, link[key]);
  });
  document.querySelector('head').appendChild(linkEl);
});

iconMeta.forEach((meta) => {
  const metaEl = document.createElement('meta');
  Object.keys(meta).forEach((key) => {
    if (key === 'content') {
      meta[key] = `assets/fav/${getProduct()}/${meta[key]}`;
    }
    metaEl.setAttribute(key, meta[key]);
  });
  document.querySelector('head').appendChild(metaEl);
});

meta.forEach((meta) => {
  const metaEl = document.createElement('meta');
  Object.keys(meta).forEach((key) => {
    if (key === 'content') {
      meta[key] = `assets/fav/${getProduct()}/${meta[key]}`;
    }
    metaEl.setAttribute(key, meta[key]);
  });
  document.querySelector('head').appendChild(metaEl);
});
