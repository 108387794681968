// Remove invalid query params
try {
  decodeURIComponent(location.href);
} catch (_) {
  const url = new URL(location.href);
  const keys = Array.from(url.searchParams.keys());
  for (let key of keys) {
    try {
      decodeURIComponent(key);
      decodeURIComponent(url.searchParams.get(key));
    } catch (_) {
      url.searchParams.delete(key);
    }
  }
  location.href = url.href;
}
